.LegalContainer {
  width: 100%;
  height: 100%;
}

.LandingHeaderContainer {
  background-color: rgb(0, 0, 139);
  height: 75px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 0px 80px;
}

.LandingHeaderContainer h1 {
  font-weight: bold;
  font-size: 20px;
  color: white;
  padding-bottom: 0;
  text-decoration: none;
}

.LegalListContainer {
  padding: 0px 80px;
}

.LegalList {
  padding: 0;
  margin: 0;
}

.LegalListItem {
  list-style-type: none;
  padding: 10px 0px;
  margin: 0px;
}

.LegalListItemLink {
  font-size: 16px;
  text-decoration: none;
}

.LegalListItemLink:hover {
  text-decoration: none;
  color: rgb(0, 0, 139);
  cursor: pointer;
}

.LegalListItem-Active {
  font-weight: bold;
  color: rgb(0, 0, 139);
}

.LegalListItem-Inactive {
  color: black;
}

.LegalViewContainer {
  padding: 40px 80px;
}

.LegalViewHeader {
  font-size: 24px;
  font-weight: bold;
}

.FAQContent {
  padding: 40px 0px;
}

.FAQContent div {
  padding: 15px 0px;
}

.FAQQuestion {
  font-weight: bold;
  font-size: 22px;
}

.FAQAnswer {
  font-size: 16px;
}

@media screen and (max-width: 767.98px) {
  .LandingHeaderContainer {
    padding: 0px 40px;
  }
  .LegalListContainer {
    padding: 0px 40px;
  }
  .LegalViewContainer {
    padding: 40px 40px;
  }
}
