.landing-container {
  width: 100%;
  height: 100%;
  color: white;
}

header {
  width: 100%;
  height: 100px;
  padding: 40px 140px 120px 140px !important;
  background-color: rgb(0, 0, 139);
}

.brand-name-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.brand-name-container h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
}

.header-actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-actions-container a {
  border: 1px solid white;
  background-color: transparent;
  color: white;
  text-decoration: none;
  padding: 15px 60px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
}

.header-actions-container a:hover {
  background-color: white;
  color: rgb(0, 0, 139);
}

.intro-panel-container {
  background-color: rgb(0, 0, 139);
}

.content-info-container {
  text-align: center;
  color: white;
}

.content-info-container h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
}

.content-info-container h2 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}

.cta-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 100px !important;
  margin: 40px 0px !important;
}

.cta-input-container .cta-input {
  width: 300px;
  box-sizing: border-box;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  gap: 10px;
  padding: 20px;
  background-color: transparent;
  border: 1px solid white;
  margin-right: 20px;
  color: white;
}

.cta-input-container .cta-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 80px;
  /* Orange */
  background: #ff7d11;
  border: 1px solid #ff7d11;
  color: rgb(0, 0, 139);
  font-size: 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
}

.cta-footer {
  margin-bottom: 60px;
}

.cta-footer span {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: white;
}

.login-link {
  font-weight: 600;
  color: #ff7d11;
  text-decoration: none;
  padding-left: 15px;
}

.intro-panel-illustration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.intro-panel-illustration-container img {
  width: 100%;
  max-width: 1110px;
  height: auto;
}

.info-panels-container {
  padding: 130px 188px;
  background-color: white;
}

.info-panel {
  width: 100%;
}

.info-panel-desc {
  background: white;
  box-shadow: 0px 0px 10px 2px rgba(35, 56, 98, 0.1);
  padding: 0px 44px;
  text-align: center;
  color: black;
}

.info-panel-desc img {
  margin-top: 77px;
  margin-bottom: 70px;
  width: 150px;
  height: 150px;
}

.info-panel-desc h1 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 30px;
}

.info-panel-desc p {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  margin-bottom: 96px;
}

.testimonals-section-container {
  padding-top: 90px;
  padding-bottom: 180px;
  background-color: black;
  color: white;
}

.testimonals-section-container h1 {
  text-align: center;
  margin-bottom: 90px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
}

.testimonial-column {
  border: 1px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px;
}

.testimonial-column p {
  font-style: italic;
  font-weight: bold;
  color: white;
  font-size: 24px;
}

.pricing-section-container {
  padding: 130px 140px 150px 140px;
  background-color: white;
  color: black;
}

.pricing-section-container h1 {
  text-align: center;
  margin-bottom: 60px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
}

.plans-row {
  background: white;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.pricing-headlines {
  padding: 40px 60px;
  background-color: black;
  color: white;
}

.pricing-headlines h2 {
  margin-bottom: 70px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
}

.pricing-headlines h3 {
  margin-bottom: 90px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
}

.pricing-headlines p {
  margin-bottom: 30px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
}

.pricing-details {
  padding: 40px 60px;
  background-color: white;
  color: white;
}

.pricing-details p {
  margin-bottom: 30px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  color: black;
}

.pricing-details .bold-details-text {
  font-weight: 700;
  font-size: 25px;
  color: rgb(0, 0, 139);
  padding-right: 15px;
}

.footer-cta-container {
  background-color: rgb(0, 0, 139);
  color: white;
  padding-top: 111px;
}

.footer-cta-container h1 {
  margin-bottom: 60px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 50px;
}

.footer-cta-illustration-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.footer-cta-illustration-container img {
  width: 100%;
  max-width: 1230px;
  height: auto;
}

@media screen and (max-width: 1000px) {
  .info-panels-container {
    padding: 130px 60px;
  }

  .pricing-container {
    margin: 20px 0px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .content-info-container {
    margin: 20px 0px 100px;
    padding: 0px 33px !important;
  }

  .cta-input-container {
    flex-direction: column;
    padding: 0px !important;
  }

  .cta-input-container .cta-input {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
    text-align: center;
  }

  .cta-input-container .cta-button {
    width: 100%;
  }

  .info-panels-container {
    margin-bottom: 0px;
    padding: 130px 0px;
    border: none;
  }

  .info-panel-desc {
    margin: 20px 0px;
  }

  .testimonals-section-container {
    padding-bottom: 0px;
  }

  .testimonial-column p {
    padding-bottom: 20px;
  }

  .pricing-section-container {
    padding: 60px 0px 0px;
  }

  .footer-cta-container {
    padding: 111px 0px 0px 0px;
  }

  .landing-container {
    text-align: center;
  }

  .brand-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .brand-name-container h1 {
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: center;
  }

  .header-actions-container {
    display: none;
  }

  .info-panel-right {
    text-align: center;
  }

  .pricing-headlines {
    padding: 40px 20px !important;
  }

  .testimonials-container {
    margin: 100px 0px;
  }
}
