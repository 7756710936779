html {
  scroll-behavior: smooth;
  padding: 0px;
  margin: 0px;
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0px;
  padding: 0px;
}

#root {
  height: 100%;
  width: 100%;
}

.main {
  height: 100%;
  width: 100%;
}

.row {
  margin: 0px;
  padding: 0px;
}

.col-12,
.col-11,
.col-10,
.col-6,
.col-4,
.col-3,
.col-2,
.col-1 {
  margin: 0px;
  padding: 0px;
}

input:focus,
select:focus {
  outline: none;
}
