.page-not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 139);
  height: 100%;
  width: 100%;
}

.page-not-found-container p {
  font-size: 44px;
  color: white;
  font-weight: bold;
  text-align: center;
}

.page-not-found-container a {
  font-size: 22px;
  color: white;
  font-weight: bold;
  text-align: center;
}
